import { initializeApp, getApps, FirebaseApp } from "firebase/app"
import {
  Auth,
  applyActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
  browserSessionPersistence,
  UserCredential,
  signInWithEmailAndPassword,
  signOut,
  connectAuthEmulator,
  sendEmailVerification,
  initializeAuth,
  getAuth,
  RecaptchaVerifier,
  PhoneAuthProvider,
  multiFactor,
  PhoneMultiFactorGenerator,
  MultiFactorUser,
  getMultiFactorResolver,
  MultiFactorResolver,
  MultiFactorError,
  SAMLAuthProvider,
  signInWithPopup,
  browserPopupRedirectResolver,
} from "firebase/auth"
import {
  connectFirestoreEmulator,
  Firestore,
  initializeFirestore,
  FirestoreSettings,
  getFirestore,
  getDoc,
  doc,
  DocumentData,
} from "firebase/firestore"
import {
  Analytics,
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics"
import {
  getPerformance,
  FirebasePerformance,
  trace,
  PerformanceTrace,
} from "firebase/performance"
import {
  getRemoteConfig,
  RemoteConfig,
  fetchAndActivate,
} from "firebase/remote-config"
import {
  Functions,
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
  HttpsCallableResult,
} from "firebase/functions"
import {
  productionAPIDocsEnabled,
  fhirDashboardEnabled,
  productionModeEnabled,
  multiFactorEnabled,
  EMAIL_CONFIRMATION_ROUTE,
  LOGIN_ROUTE,
  ORG_ADMIN_ACCESS_LEVEL,
  serviceAccountsEnabled,
  Environment,
} from "./constants"
import { AnalyticsEvent } from "firebase-functions/lib/providers/analytics"
import { UserProperties } from "./analytics"
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"
import { navigate } from "gatsby"
import { logger } from "./helpers"
import { identifySegmentUser } from "./analytics"

const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
}

let functions: Functions,
  auth: Auth,
  remoteConfig: RemoteConfig,
  firestore: Firestore,
  perf: FirebasePerformance,
  analytics: Analytics,
  firebaseApp: FirebaseApp,
  firestoreSettings: FirestoreSettings,
  resolver: MultiFactorResolver

const isBrowser = typeof window !== "undefined"
const isLocalOrTest =
  process.env.NODE_ENV !== "production" ||
  process.env.GATSBY_E2E_ACTIVE === "true"

export default function initFirebase(): void {
  if (!getApps().length && isBrowser) {
    firebaseApp = initializeApp(config)
    // Dynamic import for gatsby's server side build
    import("firebase/analytics").then(
      () => (analytics = getAnalytics(firebaseApp))
    )

    functions = getFunctions(firebaseApp)
    auth = initializeAuth(firebaseApp, {
      persistence: browserSessionPersistence,
    })

    if (
      process.env.NODE_ENV === "development" ||
      process.env.GATSBY_E2E_ACTIVE === "true"
    ) {
      /* @ts-ignore */
      self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
    }

    initializeAppCheck(firebaseApp, {
      provider: new ReCaptchaV3Provider(process.env.GATSBY_RECAPTCHA_SITE_KEY),
      isTokenAutoRefreshEnabled: true,
    })

    if (process.env.GATSBY_E2E_ACTIVE === "true") {
      firestoreSettings = {
        experimentalForceLongPolling: true,
      }
      firestore = initializeFirestore(firebaseApp, firestoreSettings)
    } else {
      firestore = getFirestore()
    }
    if (process.env.FUNCTIONS_EMULATOR === "true") {
      connectFunctionsEmulator(functions, "localhost", 5001)
      connectAuthEmulator(auth, "http://localhost:9099/")
      connectFirestoreEmulator(firestore, "localhost", 8080)
    }

    import("firebase/remote-config").then(() => {
      remoteConfig = getRemoteConfig(firebaseApp)
      initRemoteConfig()
    })

    import("firebase/performance").then(
      () => (perf = getPerformance(firebaseApp))
    )
  }
}

export const getAuthInstance = (): Auth => {
  return auth
}

export const loginWithEmailAndPW = async (
  email: string,
  password: string
): Promise<UserCredential> => {
  return await signInWithEmailAndPassword(auth, email, password)
}

export const setUpVerifier = (): RecaptchaVerifier => {
  const verifier = new RecaptchaVerifier(auth, "send-button", {
    size: "invisible",
  })
  verifier.render().then(widgetId => {
    window.recaptchaWidgetId = widgetId
  })

  return verifier
}

export const getMultiFactorUser = (): MultiFactorUser => {
  const multiFactorUser = multiFactor(auth.currentUser)
  return multiFactorUser
}

export const setResolver = (error: MultiFactorError): void => {
  resolver = getMultiFactorResolver(auth, error)
}

export const getResolver = (): MultiFactorResolver => {
  return resolver
}

export const getProvider = (): PhoneAuthProvider => {
  const phoneAuthProvider = new PhoneAuthProvider(auth)
  return phoneAuthProvider
}

export const sendVerificationWithResolver = async (
  resolver: MultiFactorResolver,
  provider: PhoneAuthProvider,
  verifier: RecaptchaVerifier
): Promise<string> => {
  const multiFactorHint = resolver.hints[0] // always take the 1st boi
  const session = resolver.session
  const options = {
    multiFactorHint,
    session,
  }
  const verificationId = await provider.verifyPhoneNumber(options, verifier)

  return verificationId
}

export const completeLogin = async (
  verificationCode: string,
  verificationId: string,
  resolver: MultiFactorResolver
): Promise<UserCredential> => {
  const phoneAuthCredential = PhoneAuthProvider.credential(
    verificationId,
    verificationCode
  )
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(
    phoneAuthCredential
  )
  const userCredential = await resolver.resolveSignIn(multiFactorAssertion)
  return userCredential
}

export const sendVerificationMessage = async (
  provider: PhoneAuthProvider,
  phoneNumber: string,
  verifier: RecaptchaVerifier,
  multiFactorUser: MultiFactorUser
): Promise<string> => {
  try {
    const session = await multiFactorUser.getSession()
    const phoneOptions = {
      phoneNumber,
      session,
    }

    const verificationId = await provider.verifyPhoneNumber(
      phoneOptions,
      verifier
    )

    return verificationId
  } catch (e) {
    if (e.code === "auth/unverified-email") {
      logger.info(`User needs to verify email ${auth?.currentUser?.email}`)
      sendVerificationEmail()
      navigate(EMAIL_CONFIRMATION_ROUTE)
    }
    if (e.code === "auth/requires-recent-login") {
      logger.info(`User needs to reauthenticate ${auth?.currentUser?.email}`)
      await logout()
      navigate(LOGIN_ROUTE)
    }
    return e.message
  }
}

export const enrollUser = async (
  verificationId: string,
  verificationCode: string,
  multiFactorUser: MultiFactorUser
): Promise<void> => {
  const phoneAuthCredential = PhoneAuthProvider.credential(
    verificationId,
    verificationCode
  )
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(
    phoneAuthCredential
  )
  await multiFactorUser.enroll(multiFactorAssertion)
}

export const logout = (): Promise<void> => {
  callCloudFunction("revokeUserSession", {}) //revokes session server-side for SOC2
  return signOut(auth)
}
export const callCloudFunction = (
  functionName: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: unknown
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<void | any> => {
  return httpsCallable(functions, functionName)(data)
}
export const sendVerificationEmail = async (): Promise<void> => {
  const user = auth.currentUser
  if (user) {
    logger.info(`Sending verification email to ${user.email}`)
    sendEmailVerification(user)
    await signOut(auth)
  }
}
export const isLoggedIn = (): boolean => Boolean(auth && auth.currentUser)

export const isUserVerified = (): boolean => {
  const user = auth?.currentUser
  if (user) {
    return user.emailVerified
  }
  return false
}

export const hasEnrolledWithMFA = (): boolean => {
  const multiFactorUser = getMultiFactorUser()
  /* @ts-ignore */
  return Boolean(multiFactorUser.enrolledFactors.length)
}

export const callSendPasswordResetEmail = (
  email: string,
  token: string
): Promise<HttpsCallableResult<{ message: string; code: number }>> => {
  return callCloudFunction("sendCustomerPortalPasswordReset", { email, token })
}

export const callVerifyPasswordResetCode = (
  actionCode: string
): Promise<string> => {
  return verifyPasswordResetCode(auth, actionCode)
}

export const passwordChange = (
  actionCode: string,
  newPassword: string
): Promise<void> => {
  return confirmPasswordReset(auth, actionCode, newPassword)
}

export const isEmailNotAutoVerified = (): boolean => {
  const user = auth.currentUser
  if (user && user.providerData) {
    return user.providerData[0]?.providerId !== "google.com"
  }
  return false
}

export const callApplyActionCode = (actionCode: string): Promise<void> => {
  return applyActionCode(auth, actionCode)
}

const deleteUser = async () => {
  const user = auth.currentUser

  if (user) {
    await user.delete()
    signOut(auth)
    console.log("user deleted")
  }
}

export const logAnalyticsEvent = (
  event: string,
  data?: AnalyticsEvent
): void => {
  data ? logEvent(analytics, event, data) : logEvent(analytics, event)
}

export const logAnalyticsEventData = (event: string, data?: unknown): void =>
  logEvent(analytics, event, data)

export const setAnalyticsUser = (uid: string): void => setUserId(analytics, uid)

export const setAnalyticsUserProperty = (
  userProperty: Partial<UserProperties>
): void => setUserProperties(analytics, userProperty)

export const initRemoteConfig = async (): Promise<void> => {
  remoteConfig.settings = {
    minimumFetchIntervalMillis: 10, // 1 hour
    fetchTimeoutMillis: 60000, //1 minutes
  }

  // Set a default value if it cannot be fetched
  remoteConfig.defaultConfig = {
    [productionAPIDocsEnabled]: false,
    [fhirDashboardEnabled]: false,
    [productionModeEnabled]: false,
    [multiFactorEnabled]: false,
    [serviceAccountsEnabled]: false,
  }

  await fetchAndActivate(remoteConfig)
}

export const callGetRemoteConfig = async (): Promise<RemoteConfig> => {
  return getRemoteConfig(firebaseApp)
}

export const callGetAuth = async (): Promise<Auth> => {
  return getAuth(firebaseApp)
}

export const callGetFirestore = async (): Promise<Firestore> => {
  return getFirestore(firebaseApp)
}

export const setPerfTrace = (name: string): PerformanceTrace => {
  return trace(perf, name)
}

export const isAdmin = async (): Promise<boolean> => {
  const user = auth.currentUser
  if (!user) return false
  const userToken = await user.getIdTokenResult()

  if (!userToken && !userToken.claims) return false
  const { claims } = userToken
  const adminAccessLevel = (claims?.adminAccessLevel as unknown) as number
  return Boolean(
    claims.systemAdmin ||
      (claims.orgId && adminAccessLevel >= ORG_ADMIN_ACCESS_LEVEL)
  )
}

export const getToken = async (): Promise<{
  userTokenResult?: unknown
  token: string
}> => {
  const user = auth.currentUser
  if (!user) return { token: "" }
  const userTokenResult = await user.getIdTokenResult()
  return { userTokenResult, token: userTokenResult.token }
}

export const refreshToken = async (): Promise<void> => {
  const user = auth.currentUser
  if (!user) return

  user.getIdToken(true)
}

export const updateIdClaims = async (
  environment: Environment
): Promise<void> => {
  await callCloudFunction("updateIdClaimsV2", { environment })
  await refreshToken()
}

export const getLoggedInUserData = async (): Promise<DocumentData> => {
  const docRef = doc(firestore, "users", auth.currentUser.uid)
  const userDoc = await getDoc(docRef)
  const userData = userDoc.data()
  return userData
}

export const getSAMLProvider = async (
  email: string
): Promise<string | boolean> => {
  // TODO: Disable SAML for now
  return false
  try {
    const { data } = await callCloudFunction("getProviderId", { email })
    return data?.message ?? false
  } catch (error) {
    logger.error(`Error getting SAML provider email=${email}: ${error.message}`)
  }

  return false
}

export const loginWithSSO = async (
  provider: string
): Promise<UserCredential> => {
  const samlProvider = new SAMLAuthProvider(provider)
  return signInWithPopup(auth, samlProvider, browserPopupRedirectResolver)
}

export const isSSOUser = async (): Promise<boolean> => {
  return auth?.currentUser?.providerData?.[0]?.providerId !== "password"
}

if (typeof window !== "undefined" && isLocalOrTest) {
  window.firebaseCleanup = deleteUser
  /* @ts-ignore */
  window.callCloudFunction = callCloudFunction
  /* @ts-ignore */
  window.logout = logout
}
